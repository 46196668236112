import { Brand } from './typings'

const steinmart: Brand = {
  clientInfo: {
    displayName: 'Stein Mart',
    accountNumberLengths: [16],
    footerDisplay: 'Steinmart.com',
    footerLink: 'https://www.steinmart.com/',
    applicationUrl:
      'https://apply.syf.com/eapply/eapply.action?clientCode=STEINMART',
    dsecApigeeClientId: {
      development: 'VBLqDQdp81gv2kZSqeioZKrZC1PQeooa',
      production: 'uPWXontsi6EePZTa2h0U783j3reupC1J'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Stein Mart credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Stein Mart en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en', 'es'],
    vanityUrl: 'Steinmart.com',
    hostname: 'steinmart.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Stein Mart Credit Card or Stein Mart Elite Credit Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Stein Mart Credit Card or Stein Mart Elite Credit Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Stein Mart. The content and documents displayed may not all be provided in Spanish.',
      es: 'Su Tarjeta de Crédito Stein Mart o Tarjeta de Crédito Stein Mart Elite es emitida por Synchrony Bank.  La Política de Privacidad de Synchrony Bank rige el uso de la Tarjeta de Crédito Stein Mart o Tarjeta de Crédito Stein Mart Elite. El uso de esta página se rige por la Política de Privacidad de Internet de Synchrony Bank, la cual es diferente a la política de privacidad de Stein Mart. Es posible que el contenido y los documentos que se muestran pueden no se proporcionen en español.'
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-855-269-2139',
      fraud: '1-855-269-2139',
      customerService: '1-866-864-2157',
      plcc: ''
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/generic/@steinmart/en/html/ess013114.html',
      es: '/essimages/cs/groups/ess_webasset/generic/@steinmart/es/html/ess013296.html'
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@steinmart/en/html/ess070758.html',
      es: '/essimages/cs/groups/ess_webasset/generic/@steinmart/es/html/ess013294.html'
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@steinmart/en/html/ess013109.html',
      es: '/essimages/cs/groups/ess_webasset/generic/@steinmart/es/html/ess013266.html'
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@steinmart/en/html/ess013112.html',
      es: '/essimages/cs/groups/ess_webasset/generic/@steinmart/es/html/ess013295.html'
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#3B3C39',
        hover: '#FBC600'
      },
      secondary: {
        hover: '#FBC600'
      },
      background: '#FBC600'
    },
    field: {
      icon: '#333333',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#3B3C39',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#161616',
    bodyBackground: '#FFFFFF',
    iconBackground: '#CACACA',
    headerBackground: '#ffffff',
    modalBackground: '#FFFFFF',
    headerDivider: '#ffffff',
    footerDivider: '#FFFFFF',
    contrastingText: '#333333',
    bodyText: '#333333',
    languageSelector: '#000000',
    focus: '#003d50',
    link: '#3B3C39',
    footer: {
      background: '#ffffff',
      text: '#333333',
      link: '#333333'
    },
    formBackground: ''
  },
  images: {
    logo: {
      en: '/assets/steinmart/logo.png',
      es: '/assets/steinmart/logo.png'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/steinmart/promo_en.png',
      es: '/assets/steinmart/promo_es.png'
    },
    voiceCplLogo: ''
  }
}

export default steinmart
